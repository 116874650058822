/* Styling for MultiSelect from react-multi-select-component */
.multi-select {
    background-color: #F4F5F7 !important;
    --rmsc-hover: #EBECF0 !important;
    --rmsc-selected: #e2e6ea !important;
    --rmsc-border: #F4F5F7 !important;
    --rmsc-gray: #7A869A !important;
    --rmsc-bg: #F4F5F7 !important;
    --rmsc-p: 10px !important;
    --rmsc-radius: 4px !important;
    --rmsc-h: 38px !important;
    border-radius: 3px;
}

.multi-select div.dropdown-heading:hover {
    background-color: #EBECF0;
}

.multi-select div.dropdown-content .panel-content {
    background-color: #fff !important;
}

.multi-select div.dropdown-heading svg {
    color: #42526E;
}

/* Styling for DatePicker from react-date-picker */
.datePicker {
    width: 100%;
    height: 40px;
    background: #F4F5F7;
    border-color: #F4F5F7;
    border-radius: 3px;
    border-width: 2px;
    border-style: solid;
}

.datePickerEmpty {
    width: 100%;
    height: 40px;
    background: #F4F5F7;
    border-color: #F4F5F7;
    border-radius: 3px;
    border-width: 2px;
    border-style: solid;
}

.datePickerEmpty ::placeholder {
    color: #7A869A;
    opacity: 1;
}

.datePickerEmpty .react-date-picker__inputGroup__month {
    display: none;
}

.datePickerEmpty .react-date-picker__inputGroup__year {
    display: none;
}

.datePickerEmpty .react-date-picker__inputGroup__divider {
    display: none;
}

.datePickerLabel {
    pointer-events: none;
    position: absolute;
    z-index: 100;
    padding: 10px;
    color: #7A869A;
}

.react-date-picker__wrapper {
    border: unset;
}

.react-date-picker__inputGroup__input:invalid {
    background: unset;
}

.react-calendar__month-view__days__day--weekend {
    color: unset;
}

.react-date-picker__inputGroup {
    color: #7A869A !important;
}

.react-date-picker__inputGroup__input {
    color: #42526E;
    outline: none;
}

.react-date-picker:hover {
    cursor: pointer;
    background-color: #EBECF0;
    border-color: #EBECF0;
}

.react-date-picker__inputGroup {
    color: #42526E;
}

.react-date-picker svg {
    stroke: #42526E;
}

.react-date-picker__button {
    padding: 2px 3px;
}

.react-date-picker__button__icon {
    stroke: #42526E !important;
}

.react-date-picker__calendar .react-calendar {
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
}

.react-date-picker * {
    text-decoration: none;
    border: none;
}

.react-date-picker abbr {
    cursor: pointer;
}